/**
 * Copyright (C) Sitevision AB 2002-2021, all rights reserved
 *
 * @author robin
 */
import $ from '@sv/jquery';
import sv from '@sv/core';

const sizeFieldCache = {};

$('.sv-has-error').each(function () {
  const $this = $(this);

  $this.on('change keyup', function () {
    $this.removeClass('sv-has-error');
    $this.off();
  });
});

const countdownCharactersLeft = function ($target) {
  const value = $target.val(),
    text = value ? value.trim() : '',
    sizeField = sizeFieldCache[$target.attr('id')],
    size = sizeField.initialSize - text.length; //Text is trimmed server side

  if (size < 0) {
    sizeField.$el.addClass('sv-textfield-limit-exceeded');
  } else {
    sizeField.$el.removeClass('sv-textfield-limit-exceeded');
  }

  sizeField.$el.text(size);
};

$('.sv-form-textfield.sv-limit-characters').each(function () {
  const $this = $(this),
    sizeField = $this.parent().find('.sv-textfield-input-size'),
    id = $this.attr('id');

  sizeFieldCache[id] = {
    $el: sizeField,
    initialSize: sizeField.text(),
  };

  $this.on('keyup', function (e) {
    countdownCharactersLeft($(e.currentTarget));
  });

  if ($this.val().length) {
    countdownCharactersLeft($this);
  }
});

$('form[data-sv-single-click]').one('submit', function () {
  $(this).find('input[type=submit]').prop('disabled', true);
});

$('form[data-sv-user-consent]').each(function () {
  const $this = $(this),
    $submit = $this.find('input[type=submit]:not(.sv-survey-next)');

  if ($submit.length) {
    const $consentCheckbox = $this.find('[data-sv-form-consent-checkbox]');

    $submit.prop('disabled', !$consentCheckbox.is(':checked'));

    $consentCheckbox.on('change', function () {
      $submit.prop('disabled', !$(this).is(':checked'));
    });
  }
});

function setCustomValidity(event, message) {
  const target = event.target;
  const validityState = target.validity;

  if (validityState.valueMissing) {
    target.setCustomValidity(message);
  } else {
     if (target.type === "radio") {
       var foundTargets = $(target).closest("form").find("[name=" + target.name + "]");
       for (var i = 0; i < foundTargets.length; i++) {
         foundTargets[i].setCustomValidity('');
       }
     } else
     {
       target.setCustomValidity('');
     }
  }
}

function onParticipantsChange(select, mandatory) {
  var visibleRowCount = 1;
  if (select.selectedIndex != -1)
  {
    visibleRowCount = parseInt(select.options[select.selectedIndex].value);
  }

  for (var i = 0; i < select.options.length; i++) {
    var rowId = select.id + "_row_" + i;
    var row = document.getElementById(rowId);

    if (i < visibleRowCount) {
      row.style.display = '';

      if (mandatory) {
        var $inputWithoutRequireAttr = $(row).find('input:not([required])');
        $inputWithoutRequireAttr.attr('required', true);
      }
    } else {
      row.style.display = 'none';

      var inputId = select.id + "_input_" + i;
      document.getElementById(inputId).value = '';

      if (mandatory) {
        var $inputWithRequireAttr = $(row).find('input[required]');
        $inputWithRequireAttr.removeAttr('required');
      }
    }
  }
}

sv.FormUtils = {
  setCustomValidity: setCustomValidity,
  onParticipantsChange: onParticipantsChange
};
